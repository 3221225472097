import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { NutritionFoodstuff } from '../domain/nutritions/nutrition-foodstuff';
import { NutritionMeal } from '../domain/nutritions/nutrition-meal';
import {
  NutritionIngredientRepository,
  NutritionIngredientSchema,
} from './nutrition-ingredient.repository';
import {
  NutritionQuantityUnitRepository,
  NutritionQuantityUnitSchema,
} from './nutrition-quantity-unit-repository';

export interface NutritionMealSchema {
  name: string;
  foodstuffs: NutritionFoodstuffSchema[];
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
  sorter: number;
}

export interface NutritionFoodstuffSchema {
  ingredient: NutritionIngredientSchema;
  quantity: number;
  quantityUnit: NutritionQuantityUnitSchema;
  labelWithoutQuantity: string | null;
  sorter: number | null;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
  alternatives: NutritionFoodstuffSchema[];
}

@Injectable()
export class NutritionMealRepository {
  constructor(
    private nutritionIngredientRepository: NutritionIngredientRepository,
    private nutritionQuantityUnitRepository: NutritionQuantityUnitRepository,
  ) {
    // NaDa
  }

  toSchema(nutritionMeal: NutritionMeal): NutritionMealSchema {
    return <NutritionMealSchema>{
      name: nutritionMeal.name,
      foodstuffs: nutritionMeal.foodstuffs
        ? nutritionMeal.foodstuffs.map((fs) => this.toFoodstuffSchema(fs))
        : [],
      sorter: nutritionMeal.sorter ?? 0,
      createdAt:
        nutritionMeal.createdAt !== undefined
          ? Timestamp.fromDate(nutritionMeal.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  fromSchema(
    schema: NutritionMealSchema,
    id?: string | undefined,
  ): NutritionMeal {
    return NutritionMeal.create(
      {
        name: schema.name,
        sorter: schema.sorter ?? 0,
        foodstuffs: schema.foodstuffs
          ? schema.foodstuffs.map((fs) => this.fromFoodstuffSchema(fs))
          : [],
        createdAt: schema.createdAt?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
      },
      new UniqueEntityID(id),
    );
  }

  toFoodstuffSchema(
    nutritionFoodstuff: NutritionFoodstuff,
  ): NutritionFoodstuffSchema {
    return <NutritionFoodstuffSchema>{
      ingredient: this.nutritionIngredientRepository.toSchema(
        nutritionFoodstuff.ingredient,
      ),
      quantity: nutritionFoodstuff.quantity,
      quantityUnit: this.nutritionQuantityUnitRepository.toSchema(
        nutritionFoodstuff.quantityUnit,
      ),
      labelWithoutQuantity: nutritionFoodstuff.labelWithoutQuantity ?? null,
      sorter: nutritionFoodstuff.sorter ?? 0,
      createdAt:
        nutritionFoodstuff.createdAt !== undefined
          ? Timestamp.fromDate(nutritionFoodstuff.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      alternatives: nutritionFoodstuff.alternatives.map((alt) =>
        this.toFoodstuffSchema(alt),
      ),
    };
  }

  fromFoodstuffSchema(
    schema: NutritionFoodstuffSchema,
    id?: string | undefined,
  ): NutritionFoodstuff {
    return NutritionFoodstuff.create(
      {
        ingredient: this.nutritionIngredientRepository.fromSchema(
          schema.ingredient,
        ),
        quantity: schema.quantity,
        quantityUnit: this.nutritionQuantityUnitRepository.fromSchema(
          schema.quantityUnit,
          schema.quantityUnit.id,
        ),
        labelWithoutQuantity: schema.labelWithoutQuantity ?? undefined,
        sorter: schema.sorter ?? 0,
        createdAt: schema.createdAt?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
        alternatives:
          schema.alternatives?.map((alt) => this.fromFoodstuffSchema(alt)) ??
          [],
      },
      new UniqueEntityID(id),
    );
  }
}
